<template>
    <b-card>
        <b-overlay :show="loading">
            <h3>Report du bug</h3>
            <b-form @submit.stop.prevent>
                <b-form-group label="Titre" label-for="input-title">
                    <b-input id="input-title" v-model="title" :state="ruleTitle || !showFormError ? null : false"></b-input>
                    <b-form-invalid-feedback :state="ruleTitle || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group label="Rapport" label-for="input-report">
                    <b-form-textarea id="input-report" v-model="report" rows="20" :state="ruleReport || !showFormError ? null : false"></b-form-textarea>
                    <b-form-invalid-feedback :state="ruleReport || !showFormError ? null : false"> Ce champ doit être renseigné </b-form-invalid-feedback>
                </b-form-group>

                <b-btn variant="success" class="d-flex ml-auto" @click="confirmBugUpload">Envoyer</b-btn>
            </b-form>
        </b-overlay>
    </b-card>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        data() {
            return {
                title: '',
                report: '',
                showFormError: false,
                loading: false,
            };
        },
        computed: {
            ...mapGetters({
                modalContext: 'app/modalContext',
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            ruleTitle() {
                return this.title !== '';
            },
            ruleReport() {
                return this.report !== '';
            },

            formValidation() {
                return [this.ruleTitle, this.ruleReport].every((val) => val === true);
            },
        },
        mounted() {
            this.resetForm();
        },
        methods: {
            resetForm() {
                this.title = '';
                this.report =
                    "Informations sur l'utilisateur\n" +
                    'Marque :\n' +
                    'Nom du point de vente :\n' +
                    'Code point de vente :\n' +
                    'Description du bug\n' +
                    'ACTUELLEMENT\n' +
                    'Description du comportement constaté\n' +
                    'ATTENDU\n' +
                    'Description du comportement attendu\n' +
                    'PARCOURS À REPRODUIRE\n' +
                    "Fonctionnalité / partie de l'interface concernée\n" +
                    'Action(s) à réaliser pour reproduire le bug\n';
            },
            confirmBugUpload() {
                if (!this.formValidation) return (this.showFormError = true);

                this.$bvModal
                    .msgBoxConfirm('Êtes-vous sûr de vouloir ajouter ce rapport de bug ?', this.modalContext())
                    .then((confirmed) => {
                        if (confirmed) this.createBug();
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            },
            createBug() {
                this.loading = true;
                let data = {
                    exp: localStorage.getItem('userEmail'),
                    obj: this.title,
                    msg: this.report,
                };

                this.$store
                    .dispatch('app/createBug', data)
                    .then((res) => {
                        this.$toast(this.successToast('Rapport de bug crée avec succès'));
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast(this.errorToast('Erreur lors de la création du rapport de bug'));
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style></style>
